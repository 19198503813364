import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'//引入axios
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import './assets/common.less'
import VueRouter from 'vue-router'
// 引入路由器
import router from '@/router/index'
import dataV from '@jiaminghi/data-view'
import localForage from 'localforage'

Vue.use(VueRouter)
Vue.use(ElementUI);
Vue.use(dataV)
Vue.use(Vuex)

Vue.config.productionTip = false
Vue.prototype.$axios = axios;//把axios挂载到vue上
Vue.prototype.$localForage = localForage;

const store = new Vuex.Store({
  state: {
    pilot: ''
  },
  mutations: {
    increment (state, n) {
      state.pilot = n
    }
  }
})

new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app')
